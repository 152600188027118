import * as toastr from "toastr";
import apiClient from "@/stuff/ApiClient";
import { IItemWithFile } from '@/model/IItemWithFile';

const fileDownload = {

    // We cannot just use the GET URL to the file in an href because
    // the server needs the authentication token in the header!  So 
    // we have to download it and create a dynamic link.  There are
    // two ways of doing this: base64 and createObjectURL:
    download: async (itemWithFile: IItemWithFile) => {
        const url = `api/file/Download?filename=${encodeURIComponent(itemWithFile.filename)}&originalfilename=${encodeURIComponent(itemWithFile.originalFilename)}&contenttype=${encodeURIComponent(itemWithFile.httpContentType)}`;
        const blob: Blob = await apiClient.getNonJson(url);
        if(!(blob instanceof Blob)) {
            toastr.error("Unexpected data from server", "Download failed");
            return;
        }
        const maxSizeForBase64 = 1024 * 100; // not sure if this is really needed?
        const anchor = document.createElement("a");
        const windowUrl = window.URL || window.webkitURL;
        if (blob.size > maxSizeForBase64 && typeof windowUrl.createObjectURL === "function") {
            console.log("### using createObjectURL");
            const fileUrl = windowUrl.createObjectURL(blob);
            anchor.download = itemWithFile.originalFilename;
            anchor.href = fileUrl;
            anchor.click();
            windowUrl.revokeObjectURL(fileUrl);
        }
        else {
            //use base64 encoding when less than set limit or file API is not available
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
                anchor.download = itemWithFile.originalFilename;
                if(typeof reader.result !== "string") {
                    toastr.error("Download failed");
                    return;
                }
                anchor.href = reader.result;
                anchor.click();
            }
        }
    }


};

    

export default fileDownload;

