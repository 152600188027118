
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import FileUpload from "@/components/FileUpload.vue"
    import apiClient from "@/stuff/ApiClient";
    import { Watch } from "vue-property-decorator";
    import { StateChanger } from "vue-infinite-loading";
    import { DocumentSearchParameters as SearchParameters} from "@/model/DocumentSearchParameters";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { ITemplateDocument, TemplateDocument } from "@/model/TemplateDocument";
    import utils from "@/stuff/Utils";
    import * as toastr from "toastr";
    import fileDownload from "@/stuff/FileDownload";

    @Component({ components: { ApiButton, FileUpload } })
    export default class TemplateDocuments extends Vue {
    
        async mounted() {
            this.isSupplierZone = this.$router.currentRoute.path.toLowerCase().indexOf("supplierzone") > -1;
            this.searchParameters.buyerID = this.isSupplierZone ? +this.$store.state.signedInUser.buyerID : 0;
            this.searchParameters.supplierID = this.isSupplierZone ? +this.$store.state.signedInUser.supplierID : 0;

            await this.loadBuyerList();
        }

        // lookups

        private buyerList: Array<LookupItem> = [];

        // properties

        private infiniteId = +new Date();
        private searchIsBusy: boolean = false;

        private isSupplierZone: boolean = false;
        private searchParameters = new SearchParameters();
        private totalCount: number = -1;
        private documents: Array<TemplateDocument> = [];
        private upload: TemplateDocument = new TemplateDocument();
        private isNew: boolean = false;

        // computed properties

        private get fields(): Array<any> { return this.isSupplierZone 
            ? [
                { key: "description", label: "Description" },
                { key: "document", label: "Document" } 
            ] 
            : [
                { key: "buyers", label: "Buyers" },
                { key: "description", label: "Description" },
                { key: "document", label: "Document" } 
            ];
        }

        private get buyerSearchOptions(): Array<ILookupItem> {
            const options = this.buyerList.filter(b => this.documents.filter(d => d.buyerIDs.indexOf(b.id) > -1).length > 0);
            return utils.selectOptions(options, "All Buyers");
        }

        private get buyerEditOptions(): Array<ILookupItem> {
            return utils.selectOptions(this.buyerList, "Global");
        }

        // watchers

        @Watch("searchParameters.buyerID")
        private onBuyerChanged() {
            this.refreshSearch();
        }

        // methods

        private async loadBuyerList() {
            this.buyerList.length = 0;
            const response: Array<ILookupItem> = await apiClient.get(`/api/buyer/lookups?supplierID=${this.searchParameters.supplierID}`);
            this.buyerList.push(...response.map(b => new LookupItem(b)));
        }

        private async  refreshSearch(event?: Event) {
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.documents = [];
            await this.search(event);
            this.infiniteId += 1;
        }

        private async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.documents.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.documents.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async search(event?: Event) {
            if (this.searchIsBusy) return;

            this.searchIsBusy = true;

            const response: { count: number; list: Array<ITemplateDocument> } = await apiClient.post("/api/document/templateDocuments", this.searchParameters, event);
            this.totalCount = response.count;
            this.documents.push(...response.list.map((d: ITemplateDocument) => new TemplateDocument(d)));
            this.searchIsBusy = false;
        }

        private iconUrl(doc: TemplateDocument): string {
            if(!doc.document.hasFile) return apiClient.resolveUrl("api/file/icon?extension=nul");
            const filename = doc.document.filename;
            const dotAt = filename.lastIndexOf(".");
            const extension = dotAt === -1 || dotAt >= filename.length - 1 ? "" : filename.substr(dotAt + 1).toLowerCase();
            return apiClient.resolveUrl(`api/file/icon?extension=${extension}`);
        }

        private filename(doc: TemplateDocument): string {
            return doc.document.hasFile ? doc.document.originalFilename : "No file uploaded";
        }

        async download(doc: TemplateDocument) {
            fileDownload.download(doc.document);
        }

        private add() {
            utils.resetObject(this.upload);
            this.isNew = true;
            this.$bvModal.show("templateDocModal");
        }

        private async edit(doc: ITemplateDocument) {
            if (this.isSupplierZone) return;
            utils.resetObject(this.upload);
            this.isNew = false;
            const item: ITemplateDocument = await apiClient.get(`/api/document/templateDocument?id=${doc.documentID}`);
            this.upload.update(item);
            this.$bvModal.show("templateDocModal");
        }

        private async save(event?: Event) {
            if (utils.isEmptyOrWhitespace(this.upload.description)) {
                toastr.warning("Please enter description");
                return;
            }

            if (!this.upload.document.hasFile) {
                toastr.warning("Please choose a file");
                return;
            }
            
            const response: { message: string } = await apiClient.post("/api/document/templateDocumentSave", this.upload, event);
            
            if (response.message != "ok") {
                toastr.warning("Failed");
                return;
            }

            toastr.success("Saved");
            this.$bvModal.hide("templateDocModal");
            this.refreshSearch()
        }

        private cancel() {
            this.$bvModal.hide("templateDocModal");
        }

        private async erase(event?: Event) {
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete this document?", {
                title: "Delete document",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/document/templateDocumentDelete", this.upload, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("templateDocModal");
            await this.refreshSearch();
        }

    }
