import { IDocument, Document } from "@/model/Document";
import { mapData } from "@/stuff/DataMapping";
import utils from "@/stuff/Utils";

export interface ITemplateDocument {
    documentID: string;
    description: string;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    document: IDocument;
    buyerIDs: Array<number>;
    buyers: string;
}

export class TemplateDocument implements ITemplateDocument {

    constructor (data?: ITemplateDocument) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ITemplateDocument) {
        mapData(data, {
            root: () => this,
            document: () => new Document()
        });
    }
    
    documentID: string = utils.emptyGuidValue;
    description: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    document: Document = new Document();
    buyerIDs: Array<number> =[];
    buyers: string = "";

}