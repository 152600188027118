import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { IItemWithFile } from "./IItemWithFile";
import { DocumentCategory } from "./Enums";

export interface IDocument {
    id: string;
    httpContentType: string;
    originalFilename: string;
    filename: string;
    name: string;
    size: number;
    category: DocumentCategory;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    uploadedDate: Date;
}

export class Document implements IDocument, IItemWithFile {

    constructor(data?: IDocument) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IDocument | null) {
        if(data) {
            mapData(data, { root: () => this });
        }
        else {
            utils.resetObject(this);
        }
    }

    id: string = "";
    httpContentType: string = "";
    originalFilename: string = "";
    filename: string = "";
    name: string = "";
    size: number = 0;
    category: DocumentCategory = DocumentCategory.None;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    uploadedDate: Date = new Date(utils.emptyDateValue);

    get isNew() {
        return utils.isEmptyId(this.id);
    }
    
    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }

    get hasFile() {
        return !utils.isEmptyOrWhitespace(this.filename);
    }
}
